<template>
  <div>
    <trac-loading v-if="loading" />
    <template>
      <trac-heading-info title="Modifiers">
        <trac-button
          class="uppercase"
          id="create-modifier"
          @button-clicked="
            $router.push({
              name: 'create-modifier',
            })
          "
        >
          Create New Modifier
        </trac-button>
      </trac-heading-info>

      <div class="bg-white mt-12 px-6 py-6 big-shadow">
        <div class="w-1/2 mb-6">
          <trac-search-input
            v-model="params.keyword"
            class-name="normal-case"
            placeholder="Search modifier..."
          />
        </div>

        <trac-dashboard-table
          :tableHeaders="tableHeaders"
          :data="modifiers.products || []"
          @row-click="({ row }) => goToModifier(row.id)"
        >
          <template #default="{ row, index }">
            <trac-dashboard-table-data>
              {{ index + 1 }}
            </trac-dashboard-table-data>
            <trac-dashboard-table-data>
              {{ row.title }}
            </trac-dashboard-table-data>
            <trac-dashboard-table-data>
              {{ row.description || "N/A" }}
            </trac-dashboard-table-data>
            <trac-dashboard-table-data
              v-if="row.variations"
              class="text-xs whitespace-no-wrap p-4 font-medium"
            >
              {{ split(row.priceRange, "-")[0] | formatPrice }}
              <span v-if="split(row.priceRange, '-')[1]">
                - {{ split(row.priceRange, "-")[1] | formatPrice }}
              </span>
            </trac-dashboard-table-data>
            <trac-dashboard-table-data v-else>
              {{
                (!(row.variations && row.variations.length) ? row.salePrice : 0)
                  | formatPrice
              }}
            </trac-dashboard-table-data>
            <trac-dashboard-table-data>
              {{ row.stockQuantity }}
            </trac-dashboard-table-data>
            <trac-dashboard-table-data>
              <div class="relative" v-click-outside="reset">
                <button
                  @click.stop
                  data-testid="actions"
                  @click="currentTagIndex = index"
                  class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2"
                >
                  Actions
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="fill-current h-4 w-4"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </button>
                <div
                  v-if="currentTagIndex === index"
                  class="absolute right-0 w-32 bg-white rounded-md shadow-xl z-20"
                >
                  <button
                    v-for="name in ['Edit', 'Delete']"
                    :key="name"
                    :data-testid="name"
                    class="block w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-500 hover:text-white"
                    @click="updateModifier(name, row)"
                  >
                    {{ name }}
                  </button>
                </div>
              </div>
            </trac-dashboard-table-data>
          </template>
        </trac-dashboard-table>

        <div class="flex justify-center mt-4">
          <trac-button
            :disabled="
              (modifiers.products && modifiers.products.length) >=
              modifiers.total_count
            "
            @button-clicked="loadMoreProducts"
          >
            {{
              (modifiers.products && modifiers.products.length) >=
              modifiers.total_count
                ? "No More Items"
                : "Load More"
            }}
          </trac-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  name: "Modifiers",
  directives: {
    ClickOutside,
  },
  data() {
    return {
      searchValue: "",
      modifiers: {
        products: [],
      },
      currentTagIndex: null,
      tableHeaders: [
        {
          name: "No.",
        },
        {
          name: "Name",
        },
        {
          name: "Details",
        },
        {
          name: "Price",
        },
        {
          name: "Available Stock",
        },
        {
          name: "Actions",
          classValue: "w-1/6",
        },
      ],
      loading: false,
      params: {
        productType: "modifier",
        keyword: "",
        limit: 20,
      },
      totalCount: 0,
    };
  },
  methods: {
    ...mapActions({
      fetchModifiers: "FETCH_PRODUCTS_V2",
      deleteModifier: "DELETE_PRODUCT_V2",
    }),
    split(val, by) {
      return val.split(by);
    },
    goToModifier(id) {
      this.$router.push({
        name: "create-modifier",
        query: {
          mode: "edit",
          id,
        },
      });
    },
    async getModifiers() {
      this.loading = true;
      try {
        const res = await this.fetchModifiers({
          params: this.params,
          includeVariants: true,
          queryText: "modifier-with-variant",
        });

        if (res.status) {
          this.modifiers = res.data || {
            products: [],
            total_count: 0,
          };
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.currentTagIndex = null;
    },
    async updateModifier(name, { id, title }) {
      if (name === "Edit") {
        this.goToModifier(id);
        return;
      }

      this.loading = true;
      try {
        const res = await this.deleteModifier(id);
        if (res.status) {
          eventBus.$emit("trac-alert", {
            message: `${title} deleted successfully`,
          });

          this.getModifiers();
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    loadMoreProducts() {
      this.params = {
        ...this.params,
        limit: this.params.limit + 10,
      };

      this.getModifiers();
    },
  },
  watch: {
    params: {
      handler() {
        this.getModifiers();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
